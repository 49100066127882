import { EnvironmentUrls } from 'Roblox';
import { eventStreamService } from 'core-roblox-utilities';
import downloadAppContants from '../constants/downloadAppConstants';
import events from '../constants/downloadAppEventStreamConstants';

const linkDelayMs = 300;
const downloadLink = downloadAppContants.isIosDevice
  ? EnvironmentUrls.appStoreLink
  : EnvironmentUrls.googlePlayStoreLink;

export const HandlePrimaryLinkClicked = forced => {
  eventStreamService.sendEvent(events.continueInApp);

  if (downloadAppContants.isIosDevice) {
    if (forced || (document.hasFocus && document.hasFocus())) {
      window.location = downloadLink;
    }
  } else {
    setTimeout(() => {
      if (forced || (document.hasFocus && document.hasFocus())) {
        window.location = downloadLink;
      }
    }, linkDelayMs);
  }
};

export const HandleAndroidStoreLink = () => {
  eventStreamService.sendEvent(events.continueInApp);

  setTimeout(() => {
    window.location = EnvironmentUrls.googlePlayStoreLink;
  }, linkDelayMs);
};

export const HandleIosStoreLink = () => {
  eventStreamService.sendEvent(events.continueInApp);

  setTimeout(() => {
    window.location = EnvironmentUrls.appStoreLink;
  }, linkDelayMs);
};

export const HandleSecondaryLinkClicked = () => {
  eventStreamService.sendEvent(events.continueInBrowser);

  setTimeout(() => {
    window.location = downloadAppContants.homeLink;
  }, linkDelayMs);
};
