import { eventStreamService } from 'core-roblox-utilities';

const { eventTypes } = eventStreamService;
const DOWNLOAD_APP = 'downloadApp';

const events = {
  continueInApp: {
    name: 'clickContinueInApp',
    type: eventTypes.formInteraction,
    context: DOWNLOAD_APP,
    params: {
      aType: 'click',
      btn: 'app'
    }
  },
  continueInBrowser: {
    name: 'clickContinueInBrowser',
    type: eventTypes.formInteraction,
    context: DOWNLOAD_APP,
    params: {
      aType: 'click',
      btn: 'browser'
    }
  }
};

export { events as default };
