import React from 'react';
import PropTypes from 'prop-types';
import DownloadAppPage from '../components/DownloadAppPage';

function DownloadAppBase({ translate }) {
  return <DownloadAppPage translate={translate} />;
}

DownloadAppBase.propTypes = {
  translate: PropTypes.func.isRequired
};

export default DownloadAppBase;
