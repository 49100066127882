import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { rootElementId } from './app.config';
import '../../../css/downloadApp/downloadApp.scss';

ready(() => {
  if (rootElementId) {
    render(<App />, document.getElementById(rootElementId));
  }
});
